import React, { useState } from "react"
import SEO from "../components/seo"

import Films from "../components/index/Films"
import FilmsMobile from "../components/index/FilmsMobile"
import styled from "styled-components"

import ContactMobile from "../components/index/ContactMobile"
import ContactDesktop from "../components/index/ContactDesktop"
import PicturesMobile from "../components/pictures/PicturesMobile"
const StyledIndex = styled.div`
  font-family: "kanit" !important;
`

export default function IndexPage(props) {
  const [contactShown, toggleContact] = useState(false)

  function handleClick() {
    contactShown === false ? toggleContact(true) : toggleContact(false)
  }

  return (
    <StyledIndex className="sfcompact" id="arnaud-bresson">
      <SEO title="Films" />
      <Films contactShown={contactShown} handleClick={handleClick} />
      <FilmsMobile />
      <ContactDesktop contactShown={contactShown} handleClick={handleClick} />
      <ContactMobile contactShown={contactShown} handleClick={handleClick} />
      <PicturesMobile />
    </StyledIndex>
  )
}
