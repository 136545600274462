import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import scrollTo from "gatsby-plugin-smoothscroll"

const StyledContact = styled.div`
  display: none;

  @media (max-width: 799px) {
    display: block;
  }
  font-size: 4.6vw;

  line-height: 1.7;
  height: 275vw;
  min-height: 275vw;
  position: relative;
  width: 100%;
    @media (max-height: 599px) {
   
  }
  transition: height 0.5s;
    text-align: left;
    background-color: white;
    color: black;
    z-index: 990;
    #contactbar {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      z-index: 999;
    }

    .award{
      margin: 2vw 0;
    }
    .contact{
      text-decoration:underline
    }
    .contact,
    .award {
      p{
        margin:0;
        }
      .up {
        font-weight: bold;
        line-height:0.8;
        margin:3vw 0 1.8vw 0;
      }
      .down {
        font-size:3vw;
        line-height:1vw;
      }
      .left, .up {
        font-weight: bold;
      }
      .left {
        font-weight: bold;
        background-color: white !important;
        overflow: hidden;
      }

      .right {
        margin-right: 5vw;
        width: 36.8vw;
        padding-left: 0.8vw;
      }
    }

    #contacts {
      width: 90vw;
      
    }
    #awards {
      width: 90vw;
      margin: 5vw 0 0 0;
    }

    #contactbar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      letter-spacing: 0 !important;
      padding-top: 0;
    }

    .contact,
    .award {
      position: relative;
      max-width: 100%;
      line-height:1.3;
    }

    #description {
      margin-top:2vh;
      font-size: 4.7vw;
      position: relative;
      max-width: 90vw;
    }

    #credits {
      max-width: 90vw;
    }

    #pictures-link {
  font-family: "SFCompact", sans-serif;
      font-weight: 600;
    }
  }

  .tiret {
    letter-spacing: -0.2vw;
  }
`

const PicturesBtn = styled.button`
  width: 100%;
  height: 10vw;
  text-align: center;
  z-index: 999;
  isolation: isolate;
  margin: 15vw 0 10vw 0;

  line-height: 6vw;
  font-size: 5.5vw;
  font-weight: bold;

  background: none;
  border: none;
  border-bottom: 1px solid white !important;
  padding: 0;
  text-align: center;
`

export default function Contact(props) {
  return (
    <StyledContact contactShown={props.contactShown} id="contact-mobile">
      <div id="contactbar">
        <div id="contacts">
          <div className="contact">
            <a
              href="https://www.instagram.com/arnowbresson"
              target="_blank"
              rel="noopener"
            >
              <span className="left">Instagram</span>
              <span className="right">@arnowbresson</span>
            </a>
          </div>

          <div className="contact">
            <a href="mailto:bressonarno@gmail.com">
              <span className="left">Contact</span>
              <span className="right">ARNAUD BRESSON</span>
            </a>
          </div>
        </div>
        <div id="awards">
          <div className="award">
            <p className="up">Rouge Director of the year</p>
            <p className="down">Club des DA 2022</p>
          </div>
          <div className="award">
            <p className="up">Rouge Direction, Club des DA 2022</p>
            <p className="down">Nike, Laylow x Vapormax</p>
          </div>
          <div className="award">
            <p className="up">Rouge Cinematography, Club des DA 2022</p>
            <p className="down">Nike, Laylow x Vapormax</p>
          </div>
          <div className="award">
            <p className="up">Rouge Editing, Club des DA 2022</p>
            <p className="down">Nike, Laylow x Vapormax</p>
          </div>
          <div className="award">
            <p className="up">White Art Direction, Club des DA 2022</p>
            <p className="down">Nike, Laylow x Vapormax</p>
          </div>
          <div className="award">
            <p className="up">BLue Sound Design, Club des DA 2022</p>
            <p className="down">Nike, Laylow x Vapormax</p>
          </div>
          <div className="award">
            <p className="up">Rouge Direction, Club des DA 2022</p>
            <p className="down">Kanye West, Heaven & Hell</p>
          </div>
          <div className="award">
            <p className="up">Rouge Art Direction, Club des DA 2022</p>
            <p className="down">Kanye West, Heaven & Hell</p>
          </div>
          <div className="award">
            <p className="up">Bleu VFX, Club des DA 2022</p>
            <p className="down">Kanye West, Heaven & Hell</p>
          </div>
          <div className="award">
            <p className="up">White Editing, Club des DA 2022</p>
            <p className="down">Beadadoobee, Last day on earth</p>
          </div>
          <div className="award">
            <p className="up">Blue Direction, Club des DA 2022</p>
            <p className="down">Beadadoobee, Last day on earth</p>
          </div>
          <div className="award">
            <p className="up">Blue Art Direction, Club des DA 2022</p>
            <p className="down">Beadadoobee, Last day on earth</p>
          </div>

          <div className="award">
            <p className="up">Yellow Pencil</p>
            <p className="down">D&AD 2022</p>
          </div>
          <div className="award">
            <p className="up">Wood Pencil</p>
            <p className="down">D&AD 2022</p>
          </div>
          <div className="award">
            <p className="up">Graphite Pencil</p>
            <p className="down">D&AD 2021</p>
          </div>
          <div className="award">
            <p className="up">Best Rock Video</p>
            <p className="down">Ukmva 2021</p>
          </div>
          <div className="award">
            <p className="up">Rouge Art Direction</p>
            <p className="down">Club des DA, 2021</p>
          </div>
          <div className="award">
            <p className="up">Boule Bleue VFX</p>
            <p className="down">Club des DA, 2021</p>
          </div>
          <div className="award">
            <p className="up">Boule Rouge Art Direction</p>
            <p className="down">Club des DA, 2021</p>
          </div>
          <div className="award">
            <p className="up">Graphite Pencil</p>
            <p className="down">D&AD, 2021</p>
          </div>

          <div className="award">
            <p className="up">Best New Director</p>
            <p className="down">Ukmva, 2020</p>
          </div>

          <div className="award">
            <p className="up">Best Visual Effects</p>
            <p className="down">Ukmva, 2020</p>
          </div>

          <div className="award">
            <p className="up">Best New Talent</p>

            <p className="down">Club des DA, 2020</p>
          </div>
          <PicturesBtn
            id="home-link"
            onClick={() => scrollTo("#picturesmobile")}
          >
            Photography
          </PicturesBtn>
        </div>
      </div>
    </StyledContact>
  )
}
