import styled from "styled-components"

const BlackLayer = styled.div`
  display: none;
  @media (min-width: 800px) {
    z-index: 1000;
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    opacity: 0.25;
    transition: opacity 1.5s 0.2s ease;
    z-index: 1;
  }
`
export default BlackLayer
