import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import { FixedButton } from "../FixedButton"

import TransitionLink from "gatsby-plugin-transition-link"

const StyledContact = styled.div`
  display: none;
  @media (min-width: 800px) {
    display: initial;
    transition-property: opacity;
    transition-timing-function: ease-in;
    font-family: kanit;
    ${props =>
      props.contactShown
        ? "opacity:1; pointer-events:auto; transition-duration: 0.7s; z-index:999; transition-delay: 0.5s;"
        : "opacity:0; pointer-events:none; transition-duration: 0.7s; z-index:0; transition-delay: 0s;"};

    position: fixed 0 0 0 0;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    #intro {
      margin-bottom: 50px;
    }

    #intro,
    #contacts,
    #awards {
      width: 420px;
      @media (min-width: 800px) and (max-width: 1000px) {
        width: 380px;
      }
      @media (min-width: 1200px) {
        width: 500px;
      }
    }

    p {
      font-size: 15px;
      font-weight: 200;
      line-height: 22px;
      letter-spacing: -0.2px;
    }

    h2 {
      font-size: 15px;
      font-weight: 700;
      line-height: 20px;
      margin: 5px 0;
      text-transform: uppercase;
      &:hover {
        font-weight: 700;
      }
    }

    #contactbar {
      display: flex;
      flex-direction: raw;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      z-index: 999;
    }
    #contactbar {
      ${props =>
        props.isLinkClicked
          ? "opacity: 0 !important; transition: opacity ease 0.45s 0 !important;"
          : ""}

      .contact,
          #intro, .diego {
        transition: opacity 0.5s ease;
      }

      &:hover {
        .contact {
          &:hover {
            font-weight: 500;
          }
        }
      }
    }
    .text {
      display: block;
    }
    .alt {
      display: none;
      font-weight: 400;
    }
    .contact,
    .award {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      &:hover {
        .text {
          display: none;
        }
        .alt {
          display: block;
        }
      }
    }
  }
  div {
    p {
      margin: 0;
    }
  }

  #credits {
    margin-top: 2px;
  }

  /* SMALL WINDOW RESPONSIVE */
  @media (max-height: 600px) {
    p {
      font-size: 12px !important;
      line-height: 17px;
    }
    #credits,
    #back,
    h2 {
      font-size: 13px !important;
      line-height: 16px;
    }

    #intro,
    #contacts,
    #awards {
      margin: 1.5vh 0 1.5vh 0;
    }
  }
  .diego {
    position: fixed;
    bottom: 2px;
    left: 0;
    right: 0;
    width: 100vw;
    font-weight: 200;
    font-size: 13px;

    height: 5vh;
    opacity: 0.4;
    padding: 5px;
    &:hover {
      opacity: 1;
      font-weight: 100;
    }
  }
`

const CloseContact = styled(FixedButton)`
  z-index: 999;

  transition-property: opacity;
  transition-timing-function: ease-in;
  ${props =>
    props.contactShown
      ? "opacity:1; pointer-events:auto; transition-duration: 0.7s; transition-delay: 0.5s;"
      : "opacity:0; pointer-events:none; transition-duration: 0.7s; transition-delay: 0s;"};
`

export default function Contact(props) {
  const [isLinkClicked, toggleClick] = useState(false)

  function clickLink() {
    toggleClick(true)
  }

  return (
    <StyledContact
      isLinkClicked={isLinkClicked}
      contactShown={props.contactShown}
    >
      <CloseContact
        id="closingcross"
        primary
        onClick={props.handleClick}
        contactShown={props.contactShown}
      >
        <Link to="/">Back</Link>
      </CloseContact>
      <div id="contactbar">
        <div id="contacts">
          <div id="intro">
            <TransitionLink
              exit={{
                delay: 0.7,
              }}
              onClick={clickLink}
              to="pictures"
            >
              <p>
                <h2>Photography</h2>
                <p className="contact">
                  <span className="sixhundred">Click here</span>&nbsp;to see my
                  photography work.
                </p>
              </p>
            </TransitionLink>
          </div>
          <a
            href="https://www.instagram.com/arnowbresson"
            target="_blank"
            rel="noopener"
          >
            <p>
              <h2>Contact</h2>
              <p className="contact">Instagram @arnowbresson</p>
            </p>
          </a>

          <a href="mailto:bressonarno@gmail.com">
            <p className="contact">
              <div className="left">Contact : Arnaud Bresson</div>
            </p>
          </a>
          <div className="contact diego" id="credits">
            <a
              href="mailto:bonjour@diegoper.net"
              target="_blank"
              rel="noopener"
            >
              Design Diego Pernet
            </a>
          </div>
        </div>
        <div id="awards">
          <h2>Awards</h2>

          <p className="award">
            <span className="text">
              Rouge Director of the year, club des DA 2022
            </span>
            <span className="alt">
              Rouge Director of the year, club des DA 2022
            </span>
          </p>
          <p className="award">
            <span className="text">Rouge Direction, Club des DA 2022</span>
            <span className="alt">Nike, Laylow x Vapormax</span>
          </p>
          <p className="award">
            <span className="text">Rouge Cinematography, Club des DA 2022</span>
            <span className="alt">Nike, Laylow x Vapormax</span>
          </p>
          <p className="award">
            <span className="text">Rouge Editing, Club des DA 2022</span>
            <span className="alt">Nike, Laylow x Vapormax</span>
          </p>
          <p className="award">
            <span className="text">White Art Direction, Club des DA 2022</span>
            <span className="alt">Nike, Laylow x Vapormax</span>
          </p>
          <p className="award">
            <span className="text">Blue Sound Design, Club des DA 2022</span>
            <span className="alt">Nike, Laylow x Vapormax</span>
          </p>
          <p className="award">
            <span className="text">Rouge Direction, Club des DA 2022</span>
            <span className="alt">Kanye West, Heaven & Hell</span>
          </p>
          <p className="award">
            <span className="text">Rouge Art Direction, Club des DA 2022</span>
            <span className="alt">Kanye West, Heaven & Hell</span>
          </p>
          <p className="award">
            <span className="text">Bleu VFX, Club des DA 2022</span>
            <span className="alt">Kanye West, Heaven & Hell</span>
          </p>
          <p className="award">
            <span className="text">White Editing, Club des DA 2022</span>
            <span className="alt">Beadadoobee, Last day on earth</span>
          </p>
          <p className="award">
            <span className="text">Blue Direction, Club des DA 2022</span>
            <span className="alt">Beadadoobee, Last day on earth</span>
          </p>
          <p className="award">
            <span className="text">Blue Art Direction, Club des DA 2022</span>
            <span className="alt">Beadadoobee, Last day on earth</span>
          </p>
          <p className="award">
            <span className="text">Yellow Pencil, D&AD 2022</span>
            <span className="alt">Heaven and Hell, Kanye West</span>
          </p>
          <p className="award">
            <span className="text">Wood Pencil, D&AD 2022</span>
            <span className="alt">Heaven and Hell, Kanye West</span>
          </p>
          <p className="award">
            <span className="text">Graphite Pencil, D&AD 2021</span>
            <span className="alt">Afterlife, Flatbush Zombies</span>
          </p>
          <p className="award">
            <span className="text">Best Rock Video, Ukmva 2021</span>
            <span className="alt">Beadadoobee, Last day on earth</span>
          </p>
          <p className="award">
            <span className="text">Rouge Art Direction, Club des DA 2021</span>
            <span className="alt">Afterlife, Flatbush Zombies</span>
          </p>
          <p className="award">
            <span className="text">Bleu VFX, Club des DA 2021</span>
            <span className="alt">Afterlife, Flatbush Zombies</span>
          </p>
          <p className="award">
            <span className="text">Best New Director, Ukmva 2020</span>
            <span className="alt">Afterlife, Flatbush Zombies</span>
          </p>
          <p className="award">
            <span className="text">Best Visual Effects, Ukmva 2020</span>
            <span className="alt">Afterlife, Flatbush Zombies</span>
          </p>
          <p className="award">
            <span className="text">Best New Talent, Club des DA 2020</span>
            <span className="alt">RL Grime, Arcus</span>
          </p>
        </div>
      </div>
    </StyledContact>
  )
}
